import Gunjan from "../assets/Gunjan.png";
import Chloe from "../assets/Chloe.png";
import Jonathan from "../assets/Jonathan.png";
import Chloé from "../assets/Asya.png";
import Gabriela from "../assets/Gabriela.png";
import Michael from "../assets/Michael.png";

export default [
    {
      name: "Gunjan",
      img: Gunjan,
    },
    {
      name: "Chloe",
      img: Chloe,
    },
    {
      name: "Jonathan",
      img: Jonathan,
    },
    {
      name: "Chloé",
      img: Chloé,
    },
    {
      name: "Gabriela",
      img: Gabriela,
    },
    {
      name: "Michael",
      img: Michael,
    },
];
