export default [
  {
    isFree: true,
    title: "FREE",
    price: 15,
    insight: true,
    copywriting: true,
    guidelines: true,
    keyword: true,
    positioning: true,
    SEO: true,
    cardColor: "#5EBFBF",
  },
  {
    isFree: false,
    title: "PREMIUM",
    price: 19.99,
    insight: true,
    copywriting: true,
    guidelines: true,
    keyword: true,
    positioning: true,
    SEO: true,
    cardColor: "#F24B6A",
  },
];
