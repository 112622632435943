export default [
  {
    isFree: true,
    title: "STARTER",
    price: 15,
    insight: true,
    copywriting: true,
    guidelines: false,
    keyword: false,
    positioning: false,
    SEO: false,
    cardColor: "#C4C4C4",
    cardLinearColor: "linear-gradient(180deg, #C4C4C4 0%, #888888 100%)",
    cardHandle: "#888888",
    promo: "Promo (Limited)",
    insightBold: true,
    copywritingBold: true,
    guidelinesBold: false,
    keywordBold: false,
    positioningBold: false,
    SEOBold: false,
  },
  {
    isFree: false,
    title: "BASIC",
    price: 4.99,
    insight: true,
    copywriting: true,
    guidelines: true,
    keyword: false,
    positioning: false,
    SEO: false,
    cardColor: "#EBA016",
    cardLinearColor: "linear-gradient(180deg, #F2A413 0%, #D5950F 100%)",
    cardHandle: "#D4950F",
    promo: "Promo (Monthly)",
    insightBold: false,
    copywritingBold: false,
    guidelinesBold: true,
    keywordBold: false,
    positioningBold: false,
    SEOBold: false,
  },
  {
    isFree: false,
    title: "STANDARD",
    price: 9.99,
    insight: true,
    copywriting: true,
    guidelines: true,
    keyword: true,
    positioning: false,
    SEO: false,
    cardColor: "#5EBFBF",
    cardLinearColor: "linear-gradient(180deg, #5EBFBF 0%, #4BABAB 100%)",
    cardHandle: "#4BABAB",
    promo: "Promo (Weekly)",
    insightBold: false,
    copywritingBold: false,
    guidelinesBold: false,
    keywordBold: true,
    positioningBold: false,
    SEOBold: false,
  },
  {
    isFree: false,
    title: "PREMIUM",
    price: 39.99,
    insight: true,
    copywriting: true,
    guidelines: true,
    keyword: true,
    positioning: true,
    SEO: true,
    cardColor: "#F24B6A",
    cardLinearColor: "linear-gradient(180deg, #F24B6A 0%, #CA3F59 100%)",
    cardHandle: "#DE1D41",
    promo: "Promo (Unlimited)",
    insightBold: false,
    copywritingBold: false,
    guidelinesBold: false,
    keywordBold: true,
    positioningBold: true,
    SEOBold: true,
  },
];
