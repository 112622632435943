import React from "react";
import { Link } from "react-router-dom";
import styles from "./TopicCard.module.css";

const TopicCard = ({ topic, cityName }) => {
  const { text, image, id, link } = topic;

  return (
    // <Link        WAIT MARKETING TO UPDATE THE CONTENTS
    //   to={{
    //     pathname: `${cityName}/${text.toLocaleLowerCase()}`,
    //     state: { topicId: id, topicName: text }
    //   }}
    // >
    //   <div
    //     className={styles.card}
    //     style={{ backgroundImage: `url(${image})` }}
    //   >
    //     <div className={styles.text}>
    //       <p className={styles.title}>{text}</p>
    //       <p className={styles.location}>{cityName}</p>
    //     </div>
    //   </div>
    // </Link>

    <a href={link} target='_bank'>
      <div
        className={styles.card}
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className={styles.text}>
          <p className={styles.title}>{text}</p>
          <p className={styles.location}>{cityName}</p>
        </div>
      </div>
    </a>
  );
};

export default TopicCard;
